<template>
	<Layout>
		<div class="row main-container mb-5">
			<div class="col-lg-12">
				<div class="card">
					<div class="card-body main-card-body">
						<div class="row justify-content-center pt-3 pb-5">
							<div class="col-lg-5">
								<div class="text-center pt-4 pb-4">
									<div class="pt-3 pb-3">
										<i class="ti-timer text-primary h1"></i>
									</div>
									<h2>
										What Gets Measured
										<br />
										Gets Accomplished
									</h2>
									<p class="text-muted">
										Stay accountable and on track with your goals using Turbo
										Time Tracker.
									</p>
									<b-button
										class="mt-2 mb-1"
										variant="success"
										@click.prevent="goToRoute('SignUp')"
									>
										Get Started
									</b-button>
									<br />
									<small class="text-muted">
										Free 30 day trial &middot; No credit card required.
									</small>
								</div>
							</div>
						</div>
						<!-- end row -->

						<div
							class="row jusify-content-center-mb5 dark-bg-container pt-5 pb-5"
						>
							<div class="offset-lg-3"></div>
							<div class="col-lg-6">
								<div class="text-center pt-4 pb-4 pl-4 pr-4">
									<h2 class="mb-4">
										What is Turbo Time Tracker?
									</h2>
									<p>
										Turbo Time Tracker is a simple time tracking web app.
									</p>
									<p>
										It makes it easy to record where your time is spent and is a great way to enhance your productivity.
									</p>
								</div>
							</div>
						</div>
						<!-- end row -->

						<div class="row justify-content-center mb-5 pt-5 pb-5">
							<div class="col-lg-12">
								<div class="text-center pt-4 pb-4">
									<h2>How it Works</h2>
								</div>
							</div>

							<!-- end col -->
							<div class="col-md-2">
								<div class="card bg-primary">
									<div class="card-body">
										<div class="text-center text-white py-4">
											<span class="mb-5">
												<i class="ti-folder how-it-works-icon" />
											</span>
											<h5 class="mb-3">Projects</h5>
											<p class="mb-0">
												Create projects to start keeping track of your
												productivity.
											</p>
										</div>
									</div>
								</div>
							</div>
							<!-- end col -->

							<!-- end col -->
							<div class="col-md-2">
								<div class="card bg-primary">
									<div class="card-body">
										<div class="text-center text-white py-4">
											<span class="mb-5">
												<i class="ti-timer how-it-works-icon" />
											</span>
											<h5 class="mb-3">Time Entries</h5>
											<p class="mb-0">
												Record your activity by creating time entries for a
												project.
											</p>
										</div>
									</div>
								</div>
							</div>
							<!-- end col -->

							<!-- end col -->
							<div class="col-md-2">
								<div class="card bg-primary">
									<div class="card-body">
										<div class="text-center text-white py-4">
											<span class="mb-5">
												<i class="ti-tag how-it-works-icon" />
											</span>
											<h5 class="mb-3">Tags</h5>
											<p class="mb-0">
												Capture more detail on where your time is spent.
											</p>
										</div>
									</div>
								</div>
							</div>
							<!-- end col -->

							<!-- end col -->
							<div class="col-md-2">
								<div class="card bg-primary">
									<div class="card-body">
										<div class="text-center text-white py-4">
											<span class="mb-5">
												<i class="ti-pie-chart how-it-works-icon" />
											</span>
											<h5 class="mb-3">Reports</h5>
											<p class="mb-0">
												Gain insights by inspecting your time entries.
											</p>
										</div>
									</div>
								</div>
							</div>
							<!-- end col -->

							<!-- end col -->
							<div class="col-md-2">
								<div class="card bg-primary">
									<div class="card-body">
										<div class="text-center text-white py-4">
											<span class="mb-5">
												<i class="ti-dashboard how-it-works-icon" />
											</span>
											<h5 class="mb-3">Dashboard</h5>
											<p class="mb-0">
												Get an overview of your recent activity at a glance.
											</p>
										</div>
									</div>
								</div>
							</div>
							<!-- end col -->
						</div>
						<!-- end row -->

						<div class="row jusify-content-center-mb dark-bg-container pt-5 pb-5">
							<div class="col-lg-12 section-text-container">
								<div class="pt-4 pb-4 pl-4 pr-4">
									<div class="text-center">
										<h2 class="text-center mb-4">Simple Pricing for Everyone</h2>
										<toggle-button
											id="toggleBtn"
											class="mb-4"
											v-model="showAnnualPlans"
											:width="120"
											:height="40"
											:speed="480"
											:color="{checked: '#626ed4', unchecked: '#626ed4'}"
											:labels="{checked: 'Yearly', unchecked: 'Monthly'}"
										/>
									</div>
									<div class="row">
										<div class="offset-xl-2 offset-md-3"></div>

										<div class="col-xl-4 col-md-6" v-for="plan in selectedPlans" :key="plan.id">
											<div class="card pricing-box">
												<div class="card-body p-4">
													<div class="media mt-2">
														<i :class="`${plan.icon} h2 align-self-center`"></i>
														<div class="media-body text-right">
															<h4 class="mt-0">{{ plan.title }}</h4>
															<p class="text-muted mb-0">{{ plan.subtitle }}</p>
														</div>
													</div>
													<div class="pricing-features mt-5 pt-2">
														<p>
															<i class="mdi mdi-check text-primary mr-2"></i> {{ plan.projects }}
														</p>
														<p>
															<i class="mdi mdi-check text-primary mr-2"></i> Unlimited Time Entries
														</p>
														<p>
															<i class="mdi mdi-check text-primary mr-2"></i> Unlimited Tags
														</p>
														<p v-if="plan.upcomingFeatures">
															<i class="mdi mdi-check text-primary mr-2"></i> {{ plan.upcomingFeatures}}
														</p>
														<div v-else class="spacer"></div>
													</div>
													<div class="text-center mt-5">
														<h1 class="mb-0">
															<sup>
																<small>£</small>
															</sup>{{ plan.price }}
															<span class="font-size-16">Per {{ plan.period }} </span>
															<span 
																v-if="plan.discount"
																class="font-size-16"
															>(Save £{{ plan.discount }}) </span>
														</h1>
													</div>
													<div class="mt-5 mb-1">
														<b-button
															class="btn btn-success btn-block"
															@click.prevent="goToRoute('SignUp')"
														>
															<span>Start Free Trial</span>
														</b-button>
													</div>
												</div>
											</div>
										</div>
										<!-- end col -->
									</div>
								</div>
							</div>
						</div>
						<!-- end row -->

						<div
							class="row jusify-content-center-mb5 pt-5 pb-5"
						>
							<div class="offset-lg-3"></div>
							<div class="col-lg-6">
								<div class="text-center pt-4 pb-4 pl-4 pr-4">
									<h2 class="mb-4">
										Turbo Time Tracker makes it super easy to track your time
										and start being more productive.
									</h2>
									<h5 class="mb-4">
										Click below to start your 30-day free trial.
									</h5>
									<b-button
										class="mt-2 mb-1"
										variant="success"
										@click.prevent="goToRoute('SignUp')"
									>
										Get Started
									</b-button>
									<br />
									<small class="text-muted">
										Free 30 day trial &middot; No credit card required.
									</small>
								</div>
							</div>
						</div>
						<!-- end row -->
					</div>
					<!-- end card-body -->
				</div>
				<!-- end card -->
			</div>
			<!-- end col -->
		</div>
		<!-- end row -->
	</Layout>
</template>

<script>
import { ToggleButton } from 'vue-js-toggle-button'
import Layout from "../../layouts/public";
import appConfig from "@/app.config";

export default {
	page: {
		title: "Home",
		meta: [{ name: "description", content: appConfig.description }],
	},
	components: {
		Layout,
		ToggleButton,
	},
	data() {
		return {
			title: "Blank",
			showAnnualPlans: false,
			items: [
				{
					text: "Turbo Time Tracker",
					href: "/",
				},
				{
					text: "Extra Pages",
					href: "/",
				},
				{
					text: "Blank",
					active: true,
				},
			],
			plans: [
				{
					id: 0,
					title: 'Free Plan',
					subtitle: 'Free forever',
					period: 'Month',
					price: '0',
					discount: null,
					projects: '1x Project',
					upcomingFeatures: false,
					icon: 'ion ion-md-stopwatch',
				},
				{
					id: 1,
					title: 'Free Plan',
					subtitle: 'Free forever',
					period: 'Year',
					price: '0',
					discount: null,
					projects: '1x Project',
					upcomingFeatures: false,
					icon: 'ion ion-md-stopwatch',
				},
				{
					id: 2,
					title: 'Basic Plan',
					subtitle: 'Monthly Pricing',
					period: 'Month',
					price: '5',
					discount: null,
					projects: 'Unlimited Projects',
					upcomingFeatures: 'Access to Upcoming Features',
					icon: 'ion ion-ios-rocket',
				},
				{
					id: 3,
					title: 'Basic Plan',
					subtitle: 'Annual Pricing',
					period: 'Year',
					price: '50',
					discount: '10',
					projects: 'Unlimited Projects',
					upcomingFeatures: 'Access to Upcoming Features',
					icon: 'ion ion-ios-rocket',
				},
			],
		};
	},
	computed: {
		selectedPlans() {
			if (this.showAnnualPlans) {
				return this.plans.filter(plan => plan.period === 'Year');
			} else {
				return this.plans.filter(plan => plan.period === 'Month');
			}
		}
	},
	methods: {
		goToRoute(name) {
			this.$router.push({ name });
		},
	},
};
</script>

<style lang="scss" scoped>
.main-container {
	margin-bottom: 20px;
}

.main-card-body {
	padding-bottom: 0;
}

.dark-bg-container {
	margin-left: -20px; // a dirty hack to make background colour stretch to full width
	margin-right: -20px;
	background: #e9ecef;
}

.how-it-works-icon {
	font-size: 1.6em;
}

.section-image-container, .secion-text-container {
	display: flex;
	align-items: center;
	justify-content: center;
}

.section-image {
	max-height: 80%;
}

#toggleBtn {
	font-size: 14px;
}

.spacer {
	height: 23px;
}
</style>